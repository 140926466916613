import React from "react";
import { Link } from "gatsby";


import eventsImage from "../images/events-image-hero.png";




import playButton from "../images/playButton.png";
import pauseButton from "../images/pauseButton.png";
import speakerUnmuted from "../images/speakerUnmuted.png";
import speakerMuted from "../images/speakerMuted.png";
import headerIndexImg from "../images/HomeHeaderImg.png"

import movie from "../video/videoplayback.mp4";
import Head from "../components/head";



//styles
import "../styles/styles.css";
import IndexStyles from "../components/index.module.css";


import {graphql} from "gatsby";

export const query = graphql`
query {
  allContentfulEvent(sort: { order: DESC, fields: date }, limit: 3) {
    edges {
      node {
        slug
        title
        date(formatString: "MMMM DD, YYYY")
        time
        locationAddress
      }
    }
  }
  allContentfulHomePage{
    edges{
      node{
        noEventsText
        headerImage{
          file{
            url
          }
        }
        missionHeadline

        missionSectionImage{
          file{
            url
          }
        }
        missionSectionHeadline
        missionSectionParagraph{
          missionSectionParagraph
        }
        programSectionTssk12Image{
          file{
            url
          }
        }
        programSectionTssk12headline
        programSectionTssK12Subheadline
        programSectionTssUgaImage{
          file{
            url
          }
        }
        programSectionTssUgaHeadline
        programSectionTssUgaSubheadline
        programSectionTssOnnyImage{
          file{
            url
          }
        }
        programSectionTssOnnyHeadline
        programSectionTssOnnySubheadline
        programSectionTssCraneImage{
          file{
            url
          }
        }
        programSectionTssCraneHeadline
        programSectionTssCraneSubheadline
        programSectionTssAthensImage{
          file{
            url
          }
        }
        programSectionTssAthensHeadline
        programSectionTssAthensSubheadline
        programSectionTssAdkImage{file{
          url
        }}
        programSectionTssAdkHeadline
        programSectionTssAdkSubheadline
        programSectionTssResearchImage{
          file{
            url
          }
        }
        programSectionTssResearchHeadline
        programSectionTssResearchSubheadline
        goalSectionHeadline
        goalSectionSubheadline
        goal1Image{
          file{
            url
          }
        }
        goal1Headline
        goal1Paragraph{
          goal1Paragraph
        }
        goal2Image{
          file{
            url
          }
        }
        goal2Headline
        goal2Paragraph{
          goal2Paragraph
        }
        goal3Image{
          file{
            url
          }
        }
        goal3Headline
        goal3Paragraph{
          goal3Paragraph
        }
      }
       
      
      }
    }
  }
 

`;

class IndexPage extends React.Component {
  // intval = React.createRef();
  
  state = {
    sayingsNumber:0,
    canPlayAudio: true,
    play: false,
    show:true,
    eventDates: [],
    mute:false,
    heroSayings:["We are part of a love story.",
    "Our name is that of a poem by Robert Frost.",
    "We exist to make a difference in individual human lives.",
    "We also do research on the affinities between music and nature.",
    "Our quest is to explore and elevate human wonder, love, ideals, and ideas through the medium of music literacy, appreciation, enjoyment, creation, and dissemination, and relate these activities to the natural order of things in the living biosphere.",
  "We dedicate our work to all who individually love and lose their individual loves."]
  }

  componentDidMount(){
    document.querySelector("video").addEventListener('ended',this.myHandler,false);

    let tempArr=[];

    for(let i = 0; i<this.props.data.allContentfulEvent.edges.length; i++){
      let todaysDate = new Date();
      let dateString = todaysDate.getTime();
      let newTime = this.props.data.allContentfulEvent.edges[i].node.date + " " +this.props.data.allContentfulEvent.edges[i].node.time
      let eventDate = new Date(newTime);
      console.log(eventDate)
        let eventDateString = eventDate.getTime(); 
        
        


        if((eventDateString > dateString) && this.props.data.allContentfulEvent.edges[i].node.title !== "null"){
                 tempArr.push(this.props.data.allContentfulEvent.edges[i])
        }
    }

//add to state.
this.setState({eventDates: tempArr})



    
// target only ones that have the correct text to be replaced instead of searching for all
Array.from(document.querySelectorAll('p')).forEach(el => {
  
  let v=el.innerHTML;
  let p1=v.replace("Better Music, Better World.", "<strong style='color:#ba0c2f; font-weight:600;'>Better Music, Better World.</strong>")
  el.innerHTML=p1;
  });
  Array.from(document.querySelectorAll('p')).forEach(el => {
   
    let v=el.innerHTML;
    let p1=v.replace("Better Music", "<strong style='color:#ba0c2f; font-weight:600;'>Better Music</strong>")
    el.innerHTML=p1;
    });

  }
 myHandler = (e)=>{
    this.setState({sayingsNumber:0, play:false, show:false });
 }
  togglePlay = (e) => {
    e.preventDefault();
 
    
    this.setState({ play: true});
    document.querySelector("video").play();
    this.intervalID = setInterval(this.traverseSayings,17000);
   
      
  }
  toggleStop = (e)=>{
    this.setState({ play: false });
        document.querySelector("video").pause();
        clearInterval(this.intervalID);
    
  }
 
  traverseSayings = (e)=>{
   
      if(this.state.sayingsNumber +1 < this.state.heroSayings.length){
        
            this.setState({ show:false},()=>{
              setTimeout(()=>{this.setState({ sayingsNumber : this.state.sayingsNumber + 1,show:true})},400)
            });
           
    }
    else{
      if(this.state.sayingsNumber +1 >= this.state.heroSayings.length){
        this.setState({show:false},()=>{
          setTimeout(()=>{this.setState({sayingsNumber:0, show:true})},400)
        });

      }
      
    }
  }
  toggleMute=(e)=>{
    e.preventDefault()
    this.setState({mute: !this.state.mute},()=>{
      if(this.state.mute === true){
        document.querySelector("video").muted = true;
      }else{
        document.querySelector("video").muted = false;
      }
    })
   
}
render(){


return (
  <>
 <Head title="Home"/>
  
  <div style={{
    height:"100vh",
    width:"100%",
    position:"relative",
    backgroundColor:"#000"
  }}>
   {this.state.play === false?<div style={{
      height:"100vh",
      width:"100%",
      position:"absolute",
      backgroundImage:`url(${this.props.data.allContentfulHomePage.edges[0].node.headerImage.file.url})`,
      backgroundSize:"cover",
      backgroundPosition:"center",
      backgroundRepeat:"no-repeat",
      zIndex:"998"
   }}> <div className={IndexStyles.heroInfoContainer}>
   <h1>BETTER MUSIC, BETTER WORLD</h1>
   <p>
   <strong>The Secret Sits Pro Musica Ensembles, Inc.</strong> is a 501(c)(3) charity
devoted to improving human lives by increasing everyday exposure 
to what we call 'better music.' 

   </p>
 </div></div>:null}
    <video width="100%" style={{height:"100vh"}}>
  <source src={movie} type="video/mp4"/>
</video>
    {this.state.sayingsNumber!==null?
    <p style={this.state.play === false?{opacity:0}:null} className={this.state.show === true?IndexStyles.show:IndexStyles.hide} id={IndexStyles.sayingsParagraph} >{this.state.heroSayings[this.state.sayingsNumber]}</p>
    :null}
    {this.state.play === false ? (
      <>
      {this.state.canPlayAudio === true?<img className={IndexStyles.playButton}  onClick={this.togglePlay} src={playButton}></img>:null}
      </>
    ):<img className={IndexStyles.pauseButton} onClick={this.toggleStop} src={pauseButton}/>}
    {/* {this.state.play === true ? :null} */}
  <span className={IndexStyles.muteButton} onClick={this.toggleMute}>{this.state.mute === true?<img style={{ height:"25px",width:"auto"}} src={speakerMuted}></img>:<img style={{ left:"50px", height:"25px",width:"auto"}} src={speakerUnmuted}></img>}</span>
    
  </div>
 
    
    <section className={IndexStyles.sayingSection}>
      <p>
        {this.props.data.allContentfulHomePage.edges[0].node.missionHeadline}
      </p>
    </section>
    <section className={IndexStyles.musicAppreciationSection}>
      <div>
        <div className={IndexStyles.teachImgContainer}>
          <img src={this.props.data.allContentfulHomePage.edges[0].node.missionSectionImage.file.url}></img>
        </div>
        <div className={IndexStyles.musicAppreciationCopyContainer}>
          <div className={IndexStyles.musicAppreciationCopy}>
            <h2>{this.props.data.allContentfulHomePage.edges[0].node.missionSectionHeadline}</h2>
            <p>
              {/* We aim to create, train, and provide cost-free opportunities for
              small music ensembles to perform live <strong style={{color:"#ba0c2f", fontWeight:"600"}}>Better Music</strong> in public
              forums. The instructor here is Serena Scibelli. */}
              {this.props.data.allContentfulHomePage.edges[0].node.missionSectionParagraph.missionSectionParagraph}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className={IndexStyles.programSection}>
      <h2>Programs</h2>
      <div className={IndexStyles.programList}>
        <div className={IndexStyles.programItemContainerOuter}>
        <Link to="/programs/tss-k12">
          <div className={IndexStyles.programItemContainer}>
            <div
              style={{ backgroundImage: `url(${this.props.data.allContentfulHomePage.edges[0].node.programSectionTssk12Image.file.url})` }}
              className={IndexStyles.programItemUpper}
            >
              <span>TSS K-12</span>
            </div>
            <div className={IndexStyles.programItemLower}>
              <h3>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssk12headline}</h3>
              <p>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssK12Subheadline}</p>
            </div>
          </div>
          </Link>
        </div>
        
        <div className={IndexStyles.programItemContainerOuter}>
          <Link to="/programs/tss-uga">
          <div className={IndexStyles.programItemContainer}>
            <div
              style={{ backgroundImage: `url(${this.props.data.allContentfulHomePage.edges[0].node.programSectionTssUgaImage.file.url})` }}
              className={IndexStyles.programItemUpper}
            >
              <span>TSS UGA</span>
            </div>
            <div className={IndexStyles.programItemLower}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssUgaHeadline}</h3>
              <p>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssUgaSubheadline}</p>
            </div>
          </div>
          </Link>
        </div>
       
        <div className={IndexStyles.programItemContainerOuter}>
        <Link to="/programs/tss-onny">
          <div className={IndexStyles.programItemContainer}>
            <div
            
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.props.data.allContentfulHomePage.edges[0].node.programSectionTssOnnyImage.file.url})`,
              }}
              className={IndexStyles.programItemUpper}
            >
              <span>TSS ONNY</span>
            </div>
            <div className={IndexStyles.programItemLower}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssOnnyHeadline}</h3>
              <p>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssOnnySubheadline}</p>
            </div>
          </div>
          </Link>
        </div>
       
        <div  className={IndexStyles.programItemContainerOuter}>
        <Link to="/programs/tss-crane">
          <div className={IndexStyles.programItemContainer}>
            <div
              style={{ backgroundImage: `url(${this.props.data.allContentfulHomePage.edges[0].node.programSectionTssCraneImage.file.url})` }}
              className={IndexStyles.programItemUpper}
            >
              <span>TSS Crane</span>
            </div>
            <div className={IndexStyles.programItemLower}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssCraneHeadline}</h3>
              <p>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssCraneSubheadline}</p> </div>
          </div>
          </Link>
        </div>


        <div className={IndexStyles.programItemContainerOuter}>
        <Link to="/programs/tss-athens">
          <div className={IndexStyles.programItemContainer}>
            <div
              style={{ backgroundImage: `url(${this.props.data.allContentfulHomePage.edges[0].node.programSectionTssAthensImage.file.url})` }}
              className={IndexStyles.programItemUpper}
            >
              <span>TSS Athens</span>
            </div>
            <div className={IndexStyles.programItemLower}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssAthensHeadline}</h3>
              <p>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssAthensSubheadline}</p>
            </div>
          </div>
          </Link>
        </div>
        <div className={IndexStyles.programItemContainerOuter}>
        <Link to="/programs/tss-adirondack">
          <div className={IndexStyles.programItemContainer}>
            <div
              style={{ backgroundImage: `url(${this.props.data.allContentfulHomePage.edges[0].node.programSectionTssAdkImage.file.url})` }}
              className={IndexStyles.programItemUpper}
            >
              <span>TSS Adirondacks</span>
            </div>
            <div className={IndexStyles.programItemLower}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssAdkHeadline}</h3>
              <p>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssAdkSubheadline}</p>
            </div>
          </div>
          </Link>
        </div>
        <div className={IndexStyles.programItemContainerOuter}>
        <Link to="/programs/tss-research">
          <div className={IndexStyles.programItemContainer}>
            <div
              style={{ backgroundImage: `url(${this.props.data.allContentfulHomePage.edges[0].node.programSectionTssResearchImage.file.url})` }}
              className={IndexStyles.programItemUpper}
            >
              <span>TSS Research</span>
            </div>
            <div className={IndexStyles.programItemLower}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssResearchHeadline}</h3>
              <p>{this.props.data.allContentfulHomePage.edges[0].node.programSectionTssResearchSubheadline}</p> </div>
          </div>
          </Link>
        </div>
      </div>
    </section>
    <section className={IndexStyles.eventSection}>
      <div className={IndexStyles.eventContainerOuter}>
        <div
          className={IndexStyles.bGImage}
          style={{ backgroundImage: `url(${eventsImage})` }}
        ></div>
        <div className={IndexStyles.eventContainerInner}>
          <div className={IndexStyles.eCIContainer}>
            <div className={IndexStyles.eCIHeading}>
              <h2>Upcoming events</h2> <Link to="/events">View all</Link>
            </div>

            {this.state.eventDates.length === 0?(<div>{this.props.data.allContentfulHomePage.edges[0].node.noEventsText}</div>):(
              this.state.eventDates.map((edge, index)=>{
  return ( <div key={index} className={IndexStyles.eCIItem}>
    <Link to={`/events/${edge.node.slug}`}>
    <p className={IndexStyles.eCIIDate}>{edge.node.date}</p>
    <h3>{edge.node.title}</h3>
    <p className={IndexStyles.eCIIAddress}>
     {edge.node.locationAddress}
    </p>
    <p className={IndexStyles.eCIITime}>{edge.node.time}</p>
    </Link>
  </div>
   );
 
})
            )}
           
          </div>
        </div>
      </div>
    </section>
    {/* <section className={IndexStyles.questSection}>
      <p>“ We exist to make a difference in individual human lives.</p>
    </section> */}
    <section className={IndexStyles.goalSection}>
      <div className={IndexStyles.goalHeaderContainer}>
        <h2>{this.props.data.allContentfulHomePage.edges[0].node.goalSectionHeadline}</h2>
        <p>{this.props.data.allContentfulHomePage.edges[0].node.goalSectionSubheadline}</p>
      </div>
      <div className={IndexStyles.goalItem}>
        <div className={IndexStyles.goalItemLeft}>
          <img src={this.props.data.allContentfulHomePage.edges[0].node.goal1Image.file.url}></img>
        </div>
        <div className={IndexStyles.goalItemRight}>
          <div className={IndexStyles.goalItemRightInner}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.goal1Headline}</h3>
            <p>
              {this.props.data.allContentfulHomePage.edges[0].node.goal1Paragraph.goal1Paragraph}
            </p>
          </div>
        </div>
      </div>
      <div className={IndexStyles.goalItemAlt}>
        <div className={IndexStyles.goalItemLeftAlt}>
          <div className={IndexStyles.goalItemRightInnerAlt}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.goal2Headline}</h3>
            <p id={IndexStyles.expParagraph}>
           {this.props.data.allContentfulHomePage.edges[0].node.goal2Paragraph.goal2Paragraph}</p>
          </div>
        </div>
        <div className={IndexStyles.goalItemRightAlt}>
          <img src={this.props.data.allContentfulHomePage.edges[0].node.goal2Image.file.url}></img>
        </div>
      </div>
      <div className={IndexStyles.goalItem}>
        <div className={IndexStyles.goalItemLeft}>
          <img src={this.props.data.allContentfulHomePage.edges[0].node.goal3Image.file.url}></img>
        </div>
        <div className={IndexStyles.goalItemRight}>
          <div className={IndexStyles.goalItemRightInner}>
            <h3>{this.props.data.allContentfulHomePage.edges[0].node.goal3Headline}</h3>
            <p>
            {this.props.data.allContentfulHomePage.edges[0].node.goal3Paragraph.goal3Paragraph}
            </p>
          </div>
        </div>
      </div>
      
     
    </section>
  
  </>
)
  
}
}
export default IndexPage;